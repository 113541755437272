import * as React from "react";
import '../styles/components/_footer.scss';

const Footer = ({handleClickMailingListAction}) => (
<footer className="footer">
	<p></p>
	<div className="footer--flexbox">
		<div className="footer--flexbox--disclaimer">
			<p>
				Jesse Rivest's (my) website does not track visitors nor send any information to any third parties. <span className="italic">However</span>,
				for this special landing page,
				I am using Meta Pixel to monitor when you click on an intended action/link.
				That action is being sent to Meta and is included in the (likely) enormous amount of data Meta has on you already;
				this extra piece of information helps me know whether advertising through Meta is worthwhile. To be honest, I feel it is pretty evil
				and wish I could just <button className="footer--mailing-list-action" onClick={handleClickMailingListAction}>email everyone</button> telling them about my music.
			</p>
			<p>
				<span className="bold">If you do click, please do have a listen!</span>
			</p>
		</div>
	</div>
	<div className="footer--flexbox">
		<p className="footer--flexbox__bottom footer--flexbox--center">
			© 2021 – {new Date().getFullYear()},
			Gatsby site by CXElegance.
		</p>
	</div>
</footer>
);

export default Footer;
