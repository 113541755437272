import React, {useState, useEffect, useRef} from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/LayoutLanding";
import Seo from "../../components/Seo";
import MyLoader from "react-spinners/ScaleLoader";
import Modal from 'react-modal';

/*
 * Content-Security-Policy (CSP) was tricky making this landing page. Here are some links that may help:
 *	https://csp-evaluator.withgoogle.com/
 *	https://cspvalidator.org/
 *	https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy
 *	https://developers.facebook.com/docs/meta-pixel/advanced#content-security-policy
 */

const NoLights20230606Page = (props) => {
	const [idFbPixel] = useState(process.env.GATSBY_LANDING_PAGE_FACEBOOK_PIXEL_20230606);
	const [reactPixel, setReactPixel] = useState(null);
	const [isPageViewed, setIsPageViewed] = useState(false);
	const [hasViewedContent, setHasViewedContent] = useState(false);
	const [isSpotifyListened, setIsSpotifyListened] = useState(false);
	const [isMailingListVisited, setIsMailingListVisited] = useState(false);
	const [isReactPixelInit, setIsReactPixelInit] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(true);
	const [spotifyLink] = useState(process.env.GATSBY_LANDING_PAGE_SPOTIFY_URL);
	const [mailingListLink] = useState(process.env.GATSBY_LANDING_PAGE_MAILING_URL);
	const msDelayFollowSpotifyLink = process.env.GATSBY_LANDING_PAGE_SPOTIFY_DELAY;
	const msDelayFollowMailingListLink = process.env.GATSBY_LANDING_PAGE_MAILING_DELAY;
	const msWaitViewedContent = process.env.GATSBY_LANDING_PAGE_MS_WAIT_VIEWED_CONTENT;
	const isSendSpotifyImmediately = process.env.GATSBY_LANDING_PAGE_SPOTIFY_IMMEDIATELY === 'true';
	const pathnameBitLyLanding = process.env.GATSBY_LANDING_PAGE_BIT_LY_PATHNAME;
	const hrefBitLyLanding = pathnameBitLyLanding && `//bit.ly/${pathnameBitLyLanding}`;
	let idTimeoutSpotifyLink = null;
	let idTimeoutMailingListLink = null;
	const refIdTimeoutViewedContent = useRef(null);

	const modalStyle = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			borderRadius: '1rem',
			marginRight: '-50%',
			overflow: 'hidden',
			padding: '40px',
			transform: 'translate(-50%, -50%)'
		}
	};

	/*
	 * When do we trigger ListenSpotify? Only when there is reactPixel available we haven't already triggered ListenSpotify.
	 * When do we send user to Spotify? Every time, after a timeout; timeout is cancelled and restarted on each click.
	 */
	const handleClickSpotify = (e) => {
		e.preventDefault();
		console.info(`handleClickSpotify has run`);
		setIsModalVisible(true);
		window.clearTimeout(idTimeoutSpotifyLink);
		idTimeoutSpotifyLink = window.setTimeout(
			() => {
				console.info(`inside TimeoutSpotifyLink function: ${idTimeoutSpotifyLink}`);
				// console.debug(reactPixel);
				console.info(`sending to Spotify now`);
				setIsModalVisible(false);
				window.location.assign(spotifyLink);
			},
			msDelayFollowSpotifyLink
		);
		if(reactPixel && !isSpotifyListened){
			let urlSearchParams = new URLSearchParams(window.location.search);
			let secret = urlSearchParams.get('secret');
			let search_string = secret;
			// console.debug(`secret is "${secret}" and search_string is "${search_string}"`);
			setIsSpotifyListened(true);
			reactPixel.trackCustom('ListenStreaming', {secret, search_string});
			console.info(`ListenStreaming has been tracked`);
			reactPixel.trackCustom('ListenSpotify', {secret, search_string});
			console.info(`ListenSpotify has been tracked`);
		}else{
			console.info(`handleClickSpotify found !reactPixel || isSpotifyListened; skipping click action`);
		}

	};

	const handleClickMailingListAction = (e) => {
		e.preventDefault();
		console.info(`handleClickMailingListAction has run`);
		setIsModalVisible(true);
		window.clearTimeout(idTimeoutMailingListLink);
		idTimeoutMailingListLink = window.setTimeout(
			() => {
				console.info(`inside TimeoutMailingListLink function: ${idTimeoutMailingListLink}`);
				// console.debug(reactPixel);
				console.info(`sending to Mailing List now`);
				setIsModalVisible(false);
				window.location.assign(mailingListLink);
			},
			msDelayFollowMailingListLink
		);
		if(reactPixel && !isMailingListVisited){
			let urlSearchParams = new URLSearchParams(window.location.search);
			let secret = urlSearchParams.get('secret');
			let search_string = secret;
			// console.debug(`secret is "${secret}" and search_string is "${search_string}"`);
			setIsMailingListVisited(true);
			reactPixel.trackCustom('VisitMailingList', {secret, search_string});
			console.info(`VisitMailingList has been tracked`);
		}else{
			console.info(`handleClickMailingListAction found !reactPixel || isMailingListVisited; skipping click action`);
		}
	}

	useEffect(
		() => {
			console.info(`useEffect for component has run`);
			const ReactPixel = require('react-facebook-pixel');
			console.info(`reactPixel is being set`);
			// console.debug(ReactPixel);
			setReactPixel(ReactPixel.default);
			return () => {
				console.info(`unmounting, reactPixel is being unset`);
				setReactPixel(null);
				setIsModalVisible(false);
			}
		},
		[]
	);

	useEffect(
		() => {
			console.info(`useEffect for reactPixel, isReactPixelInit, idFbPixel, msWaitViewedContent, hasViewedContent has run`);
			if(!idFbPixel) throw new Error(`there is no idFbPixel defined`);
			// console.debug(`idFbPixel is: ${idFbPixel}`);
			if(reactPixel && !isReactPixelInit){
				reactPixel.init(idFbPixel, {autoConfig: false}, {debug: false});
				console.info(`setting isReactPixelInit to true`);
				// console.debug(reactPixel);
				setIsReactPixelInit(true);
			}
			if(reactPixel && msWaitViewedContent && !hasViewedContent){
				window.clearTimeout(refIdTimeoutViewedContent.current);
				let urlSearchParams = new URLSearchParams(window.location.search);
				let secret = urlSearchParams.get('secret');
				let search_string = secret;
				refIdTimeoutViewedContent.current = window.setTimeout(
					() => {
						console.info(`inside TimeoutViewedContent function: ${refIdTimeoutViewedContent.current}`);
						setHasViewedContent(true);
						reactPixel.track('ViewContent', {secret, search_string});
						console.info(`setting hasViewedContent to true`);
						setIsModalVisible(false);
					},
					msWaitViewedContent
				);
			}
		},
		[reactPixel, isReactPixelInit, idFbPixel, msWaitViewedContent, hasViewedContent]
	);

	useEffect(
		() => {
			const rePath = /\/l\/20230606-no-lights/;
			const isPathOfConcern = rePath.test(props.path);
			console.info(`useEffect for reactPixel, isReactPixelInit, isPageViewed, props.path, isSendSpotifyImmediately, msDelayFollowSpotifyLink, spotifyLink has run`);
			// console.debug(`props.path is: ${props.path}`);
			// console.debug(`path test is: ${isPathOfConcern}`);
			if(reactPixel && isReactPixelInit && !isPageViewed && isPathOfConcern) {
				let urlSearchParams = new URLSearchParams(window.location.search);
				let secret = urlSearchParams.get('secret');
				let search_string = secret;
				// console.debug(`secret is "${secret}" and search_string is "${search_string}"`);
				reactPixel.pageView();
				reactPixel.trackCustom('LandedPage', {secret, search_string});
				console.info(`setting isPageViewed to true`);
				// console.debug(reactPixel);
				setIsPageViewed(true);
				if(isSendSpotifyImmediately){
					window.setTimeout(
						() => {
							console.info(`sending to Spotify immediately`);
							window.location.assign(spotifyLink);
						},
						msDelayFollowSpotifyLink
					);
				}
			}
		},
		[reactPixel, isReactPixelInit, isPageViewed, props.path, isSendSpotifyImmediately, msDelayFollowSpotifyLink, spotifyLink]
	);

	return (
	  <Layout handleClickMailingListAction={handleClickMailingListAction}>
	    <Seo title="20230606 No Lights Landing Page" />
		<Helmet>
			<meta http-equiv="Content-Security-Policy" content="default-src 'self' 'unsafe-inline' 'unsafe-eval' data: ws: http: *.facebook.net/ *.facebook.com/ *.bit.ly/" />
		</Helmet>
		<p>&nbsp;</p>
		<h3 className="landing-page">You've come this far... have a listen!</h3>
		<h4 className="landing-page">Look for my Artist Pick (or the album artwork as seen here)</h4>
		<Modal
			isOpen={isModalVisible}
			style={modalStyle}
			contentLabel="Landing Page Modal"
			ariaHideApp={false}
		>
			<MyLoader
				color={"#f75755"}
				loading={true}
				size={150}
				aria-label="Loading Indicator"
				data-testid="loader"
			/>
		</Modal>
		<div className="main--centerwrapper">
			<button onClick={handleClickSpotify} className="main--centered landingPageAction landingPageAction--top">
				<span>Listen</span>
				<span>
					<StaticImage
						src="../../images/iconmonstr-spotify-5.png"
						alt="Spotify icon"
						placeholder="blurred"
						layout="constrained"
						className="header--flexbox__icon__link__img"

						width={35}
					/>
				</span>
				<span>Spotify</span>
			</button>
		</div>
		<div className="main--centerwrapper">
			<StaticImage
				src="../../images/catalog artwork/JRIVEST15 no lights front.jpg"
				alt="Jesse Rivest - No Lights Shine Out to Me - cover art"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
			/>
		</div>
		<div className="main--centerwrapper">
			<button onClick={handleClickSpotify} className="main--centered landingPageAction landingPageAction--bottom">
				<span>Listen</span>
				<span>
					<StaticImage
						src="../../images/iconmonstr-spotify-5.png"
						alt="Spotify icon"
						placeholder="blurred"
						layout="constrained"
						className="header--flexbox__icon__link__img"
						imgClassName="img--bordered-small"
						width={35}
					/>
				</span>
				<span>Spotify</span>
			</button>
		</div>
		<div className="blowAKiss main--centerwrapper">
			<StaticImage
				src="../../images/photos/pressRes/P1020532_developed.jpg"
				alt="Jesse Rivest in Brasilia, by Jesse Rivest and Jane Lino, 2019"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
			/>
		</div>
		{hrefBitLyLanding && // we expect bit.ly to redirect to some static, simple element from our domain, like an image
			<iframe
				title="Landing page counter"
				src={hrefBitLyLanding}
				width="1px"
				height="1px"
				style={{
					border: 0, padding: 0, margin: 0, display: 'inherit'
				}}
			/>
		}
	  </Layout>
  );
};

export default NoLights20230606Page;
