import * as React from "react";
import PropTypes from "prop-types";
import '../styles/components/_header.scss';

const Header = ({ siteTitle }) => (
<header className="header">
	<h1 className="header__text">{siteTitle}</h1>

	<div className="header--flexbox--empty">
		<div className="header--flexbox__icon--empty">
		</div>
		<div className="header--flexbox__icon--empty">
		</div>
		<div className="header--flexbox__icon--empty">
		</div>
		<div className="header--flexbox__icon--empty">
		</div>
		<div className="header--flexbox__icon--empty">
		</div>
		<div className="header--flexbox__icon--empty">
		</div>
	</div>
</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
